.NavUser {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    justify-content: flex-start;
    cursor: pointer;
}

.NavUser>* {
    cursor: pointer;
}

.NavUser button {
    padding: 5px 25px 5px 25px;
    margin-left: 11px;
    font-size: 0.9rem;
    letter-spacing: 1px;
}

@media screen and (max-width: 768px) {
    .NavUser {
        display: none;
    }
}